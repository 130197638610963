function loadScript(src, onload) {
    var script = document.createElement('script');
		script.onload = onload;
		script.src = src;
		
		document.head.appendChild(script);
}

loadScript('/assets/vendor/litepicker.js', function () {
  initSpaBookingCalendar();
});

$(document).ready(function () {
  $('.spa-booking-landing-grid__hotel-guest input[type="checkbox"]').on('change', function () {
    // Only show the reservation number field if the user has said they are a hotel guest
    if ($(this).prop('checked')) {
      $(this).parents().find('.spa-booking-landing-grid__item-inner--guests-only').prop('disabled', false);
      $('input[name="IsHotelGuest"]').val('Y');
    } else {
      $(this).parents().find('.spa-booking-landing-grid__item-inner--guests-only').prop('disabled', 'disabled');
      $('input[name="IsHotelGuest"]').val('#');
    }
  });


  $('.spa-booking__form input[type="password"]').each(function () {
    $(this).parent().append('<button type="button" class="toggle-password" aria-hidden="hidden" tabindex="-1"></button>');
  });

  $('.toggle-password').on('click', function () {
    var input = $(this).siblings('input')[0];
    if (typeof input !== 'undefined') {
      if (input.type === "password") {
        input.type = "text";
      } else {
        input.type = "password";
      }
    }
  });


  $('.spa-booking__form select').selectric();

  $('.spa-booking__form select').each(function () {
    customSelectricPlaceholderState(this);
  });

  $('.spa-booking__form select').on('change', function () {
    customSelectricPlaceholderState(this);
  });

  function customSelectricPlaceholderState(selectElement) {
    if ($(selectElement).val() === '') {
      $(selectElement).parents('.selectric-wrapper').addClass('show-as-placeholder');
    } else {
      $(selectElement).parents('.selectric-wrapper').removeClass('show-as-placeholder');
    }
  }


  $('.spa-booking-date-guests-treatment select').on('change', function () {
    var durationElement = $(this).closest('.spa-booking-date-guests-treatment').next('.spa-booking-date-guests-duration');
    durationElement.find('label').show();

    durationElement.find('select').prop('required', false).val('').selectric('refresh');
    durationElement.find('> div').hide();
    durationElement.find('> div[data-treatment="' + $(this).val() + '"]').show().find('select').prop('required', true);

    durationElement.find('> div').find('select, input[type="hidden"]').removeAttr('name');
    durationElement.find('> div[data-treatment="' + $(this).val() + '"]').find('select, input[type="hidden"]').attr('name', 'OptionTypeIds[' + durationElement.data('option-type-index') + ']');
  });


  $('.spa-header__summary-toggle').on('click', function () {
    $(this).toggleClass('open');
    $(this).parents('.spa-header__summary').find('.spa-header__summary-table').slideToggle();
  });

  // Click anywhere outside of the summary to close
  $(window).on('click', function () {
    $('.spa-header__summary-toggle').removeClass('open');
    $('.spa-header__summary-table').slideUp();
  });
  
  $('.spa-header__summary').on('click', function (event) {
    event.stopPropagation();
  });


  $('.spa-booking__form-guests-item-info button').on('click', function(e) {
    e.preventDefault();
    e.stopPropagation();

    $(this).siblings('.spa-booking__form-guests-item-info-popup').fadeIn();

    $(document).on('click', function () {
      if ($('.spa-booking__form-guests-item-info-popup').is(':visible')) {
        $('.spa-booking__form-guests-item-info-popup').fadeOut();
      }
    })
  });

  $('.spa-booking__form-guests-item-info-popup .close').on('click', function (e) {
    e.preventDefault();
    $(this).parent().fadeOut();
  });


  $('.spa-booking-treatment-options__item-selector input').on('change', function () {
    $('.spa-booking-treatment-options__item-selector label').text('Select');
    if ($(this).prop('checked')) {
      $(this).siblings('label').text('Selected');
    }
    // Auto submit on selection
    $('.spa-booking__main').addClass('show-loading-overlay');
    $('.spa-booking-treatment-options').trigger('submit');
  });

  var spaBookingPaymentIframe = $('#spaBookingPaymentIframe');

  if (spaBookingPaymentIframe.length > 0) {
    loadScript('/assets/vendor/rxp-js.min.js', function () {
      initSpaBookingPaymentIframe(spaBookingPaymentIframe);
    });
  }
});


function initSpaBookingCalendar() {
  var today = new Date();
  var tomorrow = new Date();
  var yesterday = new Date();
  tomorrow.setDate(today.getDate() + 1);
  yesterday.setDate(today.getDate() - 1);

  if ($('.spa-booking__datepicker').length > 0) {
    datepicker = new Litepicker({ 
      element: $('.spa-booking__datepicker')[0],
      format: 'DD/MM/YYYY',
      minDate: yesterday,
      minDays: 1,
      singleMode: true,
      showTooltip: false,
      disallowLockDaysInRange: true
    });

    datepicker.on('selected', function(date, calecalendarIdx) {
      // Auto submit on date selection
      $('.spa-booking__main').addClass('show-loading-overlay');
      $('.spa-booking__form').trigger('submit');
    });
  }

  if (typeof window.unavailableDates == 'undefined') {
    window.unavailableDates = [];
  }

  if ( $('#treatment-date').length > 0 ) {
    datepicker = new Litepicker({ 
      element: document.getElementById('treatment-date'),
      //inlineMode: true,
      format: 'DD/MM/YYYY',
      //startDate: today,
      minDate: yesterday,
      minDays: 1,
      singleMode: true,
      showTooltip: false,
      disallowLockDaysInRange: true
    });

    datepicker.on('selected', function(date, calecalendarIdx) {
      getUnavailableDates(date);
    });

    /* datepicker.on('change:month', function(date, calecalendarIdx) {
      getUnavailableDates(datepicker);
    }); */

    //getUnavailableDates(datepicker.DateTime());
  }

  if ($('.spa-booking-treatment-options__grid').length > 0) {
    equalizeHeights('.spa-booking-treatment-options__grid', '.spa-booking-treatment-options__item-content-inner');
    $('.spa-booking-treatment-options').on('filter-changed', function () {
      setTimeout(function () {
        equalizeHeights('.spa-booking-treatment-options__grid', '.spa-booking-treatment-options__item-content-inner');
      }, 505);
    });
  }
}

function getUnavailableDates(selectedDate) {
  var monthsShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  var daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  var originalDate = new Date(selectedDate.getTime());
  var startDate = getPreviousMonday(selectedDate);

  $('.spa-booking-calendar__view').show().addClass('loading');

  $('.spa-booking-calendar__view').empty();
  var dateSelect = new Date(startDate.getTime());
  for (var i=0; i<14; i++) {
    var dateValue = dateSelect.getFullYear() + '-' + pad(dateSelect.getMonth() + 1*1, 2) + '-' + pad(dateSelect.getDate(), 2);

    var markup = '<div class="spa-booking-calendar__view-date">';
    markup += '<input type="radio" name="spa-date" id="spa-date-' + dateValue + '" value="' + dateValue + '" required>';
    markup += '<label for="spa-date-' + dateValue + '" value="' + dateValue + '">';
    markup += dateSelect.getDate() + ' ' + monthsShort[dateSelect.getMonth()] + ' <span>' + daysOfWeek[dateSelect.getDay()];
    markup += '</span></label></div>';
    $('.spa-booking-calendar__view').append(markup);
    dateSelect.setDate(dateSelect.getDate() + 1);
  }

  //var firstMonth = new Date(calendar.calendars[0].dateInstance);
  
  var request = $('.spa-booking-calendar__view').data('request-url');
  request += '&from=' + startDate.getFullYear() + '-' + pad(startDate.getMonth() + 1*1, 2) + '-' + pad(startDate.getDate(), 2);

  $.ajax({
    type: 'GET',
    dataType: 'json',
    url: request,
    data: '',
    complete: function(data, status) {
      $('.spa-booking-calendar__view').removeClass('loading');

      if ( status == 'error' ) {
        console.error('Unable to load calendar data for URL');
        return;
      }
      
      if ( typeof data.responseJSON['unavailableDates'] !== 'undefined' ) {
        $('.spa-booking-calendar__view-date').each(function () {
          var dateValue = $(this).find('input').val();
          for (date in data.responseJSON['unavailableDates']) {
            var dateValueObj = new Date(dateValue);

            if (data.responseJSON['unavailableDates'][date] === dateValue) {
              $(this).addClass('disabled');
              $(this).find('input').prop('disabled', true);
            } 
          }

          if (dateValueObj.getDate() === originalDate.getDate()) {
            if (!$(this).find('input').prop('disabled')) {
              $(this).find('input').prop('checked', true);
            }
          }
        });
      }
    }
  });

  function getPreviousMonday(date) {
    var dayOfWeek = date.getDay(); // Get current day of week (0 = Sunday, 1 = Monday, ...)
    var daysToSubtract = dayOfWeek === 0 ? 6 : dayOfWeek - 1; // Calculate days to subtract to get to the previous Monday
    date.setDate(date.getDate() - daysToSubtract); // Subtract the days
    return date;
  }


  function pad(n, width, z) {
    const N = n.toString();
    const Z = z || '0';
  
    return N.length >= width ? N : new Array(width - N.length + 1).join(Z) + N;
  }
}

function initSpaBookingPaymentIframe($spaBookingPaymentIframe) {
  RealexHpp.setHppUrl($spaBookingPaymentIframe.data('payment-url'));
  RealexHpp.embedded.init(
    'autoload',
    'spaBookingPaymentIframe',
    $spaBookingPaymentIframe.data('payment-response-url'),
    $spaBookingPaymentIframe.data('payment-json')
  );
}



function equalizeHeights(containerClass, childClass) {
  var container = document.querySelector(containerClass);
  var boxes = container.children;

  // Store the original heights
  var originalHeights = [];

  // Reset child heights to auto to calculate natural heights
  for (var i = 0; i < boxes.length; i++) {
      var child = boxes[i].querySelector(childClass);
      if (child) {
          originalHeights[i] = child.style.height;
          child.style.height = 'auto';
      }
  }

  // Group boxes by their row offset
  var rows = {};
  for (var i = 0; i < boxes.length; i++) {
      var box = boxes[i];
      var top = box.offsetTop;

      if (!rows[top]) {
          rows[top] = [];
      }
      rows[top].push(box);
  }

  // Equalize heights of child elements by row
  for (var row in rows) {
      var maxHeight = 0;

      // Find the maximum height of the child elements in the current row
      for (var i = 0; i < rows[row].length; i++) {
          var child = rows[row][i].querySelector(childClass);
          if (child && child.offsetHeight > maxHeight) {
              maxHeight = child.offsetHeight;
          }
      }

      // Set all child elements in the current row to the maximum height
      for (var i = 0; i < rows[row].length; i++) {
          var child = rows[row][i].querySelector(childClass);
          if (child) {
              child.style.height = maxHeight + 'px';
          }
      }
  }
}

// GTM ecommerce tracking
jQuery(function () {
  function bake_cookie(name, value) {
    var cookie = [name, '=', JSON.stringify(value), '; domain=.', window.location.host.toString(), '; path=/;'].join('');
    document.cookie = cookie;
  }

  function read_cookie(name) {
    var result = document.cookie.match(new RegExp(name + '=([^;]+)'));
    result && (result = JSON.parse(result[1]));
    return result;
  }

  var hotelName = '';

  if (window.location.href.indexOf('chewton') > -1) {
    hotelName = "Chewton Glen";
  } else if (window.location.href.indexOf('cliveden') > -1) {
    hotelName = "Cliveden House";
  } else if (window.location.href.indexOf('lygon') > -1) {
    hotelName = "Lygon Arms";
  }

  
  // 1. Date & Guests Step
  $('.spa-booking-date-guests button[type="submit"]').on('click', function () {
    var itemsList = [];

    $('.spa-booking-guest-treatments__guest-details').each(function () {
      var numberOfGuests = $('input[name="NumberOfGuests"]:checked').val();
      var iterations = 1;

      // Need to loop experiences so that all guests get the same package/option applied.
      // This happens when there are multiple guests but the booking engine only gives 1 set of choices.
      if (!isNaN(numberOfGuests)) {
        if (numberOfGuests !== $('.spa-booking-guest-treatments__guest-details').length) {
          iterations = numberOfGuests;
        }
      }

      for (var i = 0; i < iterations; i++) {
        itemsList.push({
          'Package': $(this).find('.spa-booking-date-guests-treatment option:selected').text() || '',
          'Option': $(this).find('.spa-booking-date-guests-duration option:selected').text() || '',
          'Treatments': {
            'items': []
          },
          'Add-ons': {
            'items': []
          }
        });
      }
    });

    var dateComponents = $('#ArrivalDate').val().split('/');
    var formattedDate = dateComponents[2] + '-' + dateComponents[1] + '-' + dateComponents[0];

    var itemsObject = {
      'items': itemsList
    };

    var dataLayerData = {
      'event' : 'DatesGuestsSelection',
      'Hotel': hotelName,
      'Date': formattedDate || '',
      'guestQuantity': $('input[name="NumberOfGuests"]:checked').val() || '' ,
      'Guests': itemsObject
    };

    // console.table(dataLayerData);
    
    bake_cookie('spa_booking_date', formattedDate);
    bake_cookie('spa_booking_object_data', itemsObject);
    dataLayer.push(dataLayerData);
  });

  // 2. Guest details and treatments step
  $('.spa-booking__section-content--options button[type="submit"]').on('click', function () {
    var formattedDate = read_cookie('spa_booking_date');
    var itemsObject = read_cookie('spa_booking_object_data');

    var guestCount = 0;
    $('.spa-booking-guest-treatments__guest').each(function () {
      var treatmentsList = [];
      var addonsList = [];

      $(this).find('.spa-booking-guest-treatments__guest-treatments .spa-booking-guest-treatments__guest-basket-item').each(function () {
        var buttonParams = new URLSearchParams($(this).find('button').attr('formaction'));
        treatmentsList.push({
          'item_id': buttonParams.get('itemTypeId'),
          'item_name': $(this).find('h3').text()
        });
      });

      $(this).find('.spa-booking-guest-treatments__guest-addons .spa-booking-guest-treatments__guest-basket-item').each(function () {
        addonsList.push({
          'item_id': 0,
          'item_name': $(this).find('h3').text()
        });
      });

      itemsObject['items'][guestCount]['Treatments']['items'].push(treatmentsList);
      itemsObject['items'][guestCount]['Add-ons']['items'].push(addonsList);

      guestCount += 1;
    });


    var dataLayerData = {
      'event' : 'GuestInfoTreatmentSelection',
      'Hotel': hotelName,
      'Date': formattedDate || '',
      'guestQuantity': guestCount,
      'Guests': itemsObject
    };

    // console.table(dataLayerData);
    
    dataLayer.push(dataLayerData);
    bake_cookie('spa_booking_data_for_review', dataLayerData);
  });
  
  // 3. Review step 
  $('.spa-booking__section-content--review button[type="submit"]').on('click', function () {
    var dataLayerData = read_cookie('spa_booking_data_for_review');
    if (dataLayerData !== null) {
      dataLayerData['event'] = 'BeginCheckout';
      dataLayer.push(dataLayerData);
    }
  });

  // 4. Confirm
  // (this step is tracked by code set by the back end)
});
